import { differenceInDays, formatDistanceStrict } from 'date-fns'

import { selectDateFnsLocale } from '~/helpers/selectDateFnsLocale'

import type { Language } from '~/types/language'

/**
 * It's important to note that this should be called "formatTimeSinceRightNowInThePresentMoment"
 */
export function formatTimeSince(date: Date, language: Language): string {
  return formatDistanceStrict(date, new Date(), {
    locale: selectDateFnsLocale(language),
    addSuffix: true,
  })
}

export function toUTC(date: Date): number {
  return Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  )
}

export function remainingDays(date: string, maxDays = 7 as number): number {
  const diff = differenceInDays(toUTC(new Date(date)), toUTC(new Date()))

  return diff <= maxDays ? diff : maxDays
}
